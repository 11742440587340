<template>
  <main class="flex flex-col gap-y-8 items-center md:pt-12">
    <h1 class="font-bold text-4xl font-header-1 text-center px-2">Select preferred bootcamp option</h1>
    <div class="grid gap-8 grid-rows-2 md:grid-cols-2 md:grid-rows-1 px-8 w-full lg:w-4/6 mx-auto">
      <!-- Portfolio Bootcamp -->
      <div class="rounded-2xl bg-[#F2F2F2] px-6 py-8 font-body flex flex-col gap-y-6">
        <h2 class="text-[#1A1F1A] font-semibold text-2xl">Portfolio Bootcamp</h2>
        <ul class=" my-4 text-sm font-medium text-[#3F3E3E] space-y-3">
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Build 4 portfolio items in 4 weeks
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Weekly Project Presentation
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Work in a team of 7-10 interns from your track
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Access to weekly review of projects from Mentors
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            CV Review
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Recommendation Letter
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Certificate of Participation
          </li>
        </ul>
        <button @click="selectType('portfolio')" class="block w-full mt-auto h-fit rounded-md py-2 bg-[#D8DCD8] hover:bg-primary-green font-semibold text-sm">Select</button>
      </div>
      <!-- Portfolio Bootcamp -->
      <!-- Startup Bootcamp -->
      <div class="rounded-2xl bg-[#F2F2F2] px-6 py-8 font-body flex flex-col gap-y-6">
        <h2 class="text-[#1A1F1A] font-semibold text-2xl">Startup Bootcamp</h2>
        <ul class=" my-4 text-sm font-medium text-[#3F3E3E] space-y-3">
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Become a part of a fast-paced startup
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Weekly Project Presentation
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Real-life work experience
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Recommendation Letter
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            Certificate of Participation
          </li>
          <li>
            <span class="iconify inline-block" data-icon="mdi:check" style="color: #52a350;"></span>
            CV Review
          </li>
        </ul>
        <button @click="selectType('startup')" class="w-full mt-auto rounded-md py-2 bg-[#D8DCD8] hover:bg-primary-green font-semibold text-sm">Select</button>
      </div>
      <!-- Startup Bootcamp -->
    </div>
  </main>
</template>

<script setup>
import axios from "axios";
import swal from "sweetalert";
import { computed, inject, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

let $loading = inject("$loading");
const router = useRouter();
const store = useStore()

const user = computed(() => {
  return store.state.auth.user;
})

const selectType = async (type = null) => {
  if (type == 'startup') {
    return swal({
      title: "Oops!",
      text: `The startup bootcamp is currently closed for selection!`,
    });
  }
  let loader = $loading.show()
  try {
    await axios.post("v2/auth/bootcamp/type", { type, source: "internship" });
    await store.dispatch("auth/attempt", {});
    loader.hide()
    return router.replace({name: "BootcampWaitPage"});
  } catch (error) {
    loader.hide()
    if (error?.response?.status == 401) {
      return router.replace({name: "SelectBatchPage"});
    }
    console.error(error);    
  }
}

onMounted(()=>{
  if (user.value.bootcamp?.type) {
    return router.push({name: "BootcampWaitPage"}); 
  }
})
</script>

<style>

</style>